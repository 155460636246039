import { Checkbox, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, FormControl, FormControlLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";

import ProductUnit from '../../../communicator/http/Products';
import { translate } from '../../../helper/multilingual';
import Message from '../../../elements/message';
import AdminUnit from '../../../communicator/http/Admins';
import NotFound from '../../NotFound';
import { hasPermission } from '../../../helper/functions/auth';
import TextEditor from '../../../elements/texteditor';
import ImageInput from '../../../elements/imageInput';
import { formatMoney } from '../../../helper/functions/formatPrice';
import wrlog from '../../../helper/functions/wrlog';
import { isJsonString } from '../../../helper/functions/isJsonString';
import { formatPercent } from '../../../helper/functions/formatPercent';


/**
 * 
 * @returns Admin Product Detail Page
 */
const ProductSingle = () => {

    const params = useParams();

    const productId = params.id;

    const [loading, setLoading] = useState(productId !== 'new');
    const [product, setProduct] = useState(true);

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [askDeleteMessageOpen, setAskDeleteMessageOpen] = useState(false);
    const [deliveryDatesUpdateMessageOpen, setDeliveryDatesUpdateMessageOpen] = useState(false);
    const [deliveryDaysDefault, setDeliveryDaysDefault] = useState([]);

    const [imageData, setImageData] = useState(null);

    const [is404, setIs404] = useState(false);

    const { handleSubmit, setValue, control, formState: { errors }, getValues } = useForm();

    /**
     * Inititally check if productId is "new" to check if product is updated or new product is created
     */
    useEffect(() => {

        const productUnit = new ProductUnit();

        /**
         * if productId !== set set 404 = true
         */
        if (productId === undefined || productId === null || productId === "") {
            setIs404(true);
            return;
        }

        /**
         * if productId !== new --> update product & load productData
         */
        if (productId !== 'new') {

            // Check if product can update || delete

            if (!hasPermission('product', ['can_update', 'can_delete'])) {
                setIs404(true);
                return;
            }

            productUnit.getProduct(productId, (product) => {

                setLoading(false);

                setValue('sku', product.sku);
                setValue('title', product.title);
                setValue('description', product.description);
                setValue('futurePurchasesInterval', product.futurePurchasesInterval);
                setValue('intervalOfDelivery', product.intervalOfDelivery);
                setValue('deliveryDays', product.deliveryDays !== undefined && isJsonString(product.deliveryDays) ? JSON.parse(product.deliveryDays) : []);
                setValue('maxPauseTime', product.maxPauseTime);
                setValue('cutOffDate', product.cutOffDate);
                setValue('price', formatMoney(product.price, false));
                setValue('reducedPrice', formatMoney(product.reducedPrice, false));
                setValue('taxRate', formatPercent(product.taxRate, true));

                setDeliveryDaysDefault(product.deliveryDays !== undefined && isJsonString(product.deliveryDays) ? JSON.parse(product.deliveryDays) : []);

                setProduct(product);

            }, () => {
                setIs404(true);
            })
        } else {
            if (!hasPermission('product', ['can_create'])) {
                setIs404(true);
                return;
            }

            setValue('intervalOfDelivery', "");
            setValue('maxPauseTime', "");
            setValue('cutOffDate', "");
            setValue('deliveryDays', []);

        }


    }, [productId]);

    /**
     * Submit productData to API
     * @param {object} data 
     */
    const doSubmit = (data) => {
        const productUnit = new ProductUnit();

        let deliveryDaysChanges = false;
        if (data.deliveryDays.length !== deliveryDaysDefault.length) {
            deliveryDaysChanges = true;
        }

        deliveryDaysDefault.forEach(deliveryDay => {
            if (!data.deliveryDays.includes(deliveryDay)) {
                deliveryDaysChanges = true;
            }
        })
        data.deliveryDays.forEach(deliveryDay => {
            if (!deliveryDaysDefault.includes(deliveryDay)) {
                deliveryDaysChanges = true;
            }
        })

        setLoading(true);

        let body = {
            sku: data.sku,
            title: data.title,
            description: data.description || "",
            future_purchases_interval: data.futurePurchasesInterval,
            interval_of_delivery: data.intervalOfDelivery,
            delivery_days: JSON.stringify(data.deliveryDays),
            max_pause_time: data.maxPauseTime,
            cut_off_date: data.cutOffDate,
            price: data.price,
            reduced_price: data.reducedPrice || "",
            tax_rate: data.taxRate || "",
        }

        if (imageData !== null) {
            body.image = {
                base64: imageData.base64,
                title: imageData.title,
                mime_type: imageData.mimeType
            }
        }


        // Success function for Create Product
        const cbSuccess = (res) => {
            setLoading(false);
            window.location.replace(`/products/${res.id}`); //Replace Location to new created product & reload page
        }

        // Success function for Update Product
        const cbSuccessUpdate = (res) => {
            setLoading(false);

            setErrorMessageButtonText("OK");
            setErrorMessageType("success");
            setErrorMessageOpen(true);
            setErrorMessageSmall(true);
            setErrorMessageTitle(res.exception.message);
            setErrorMessageBody("");

            if (deliveryDaysChanges) {
                setDeliveryDatesUpdateMessageOpen(true);
            }

            setProduct(data);
        }

        const cbError = (err) => {
            setLoading(false);

            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        if (productId === 'new') {
            productUnit.createProduct(body, cbSuccess, cbError);
        } else {
            body.id = productId;
            productUnit.updateProduct(body, cbSuccessUpdate, cbError);
        }
    }

    /**
     * Delete current product
     */
    const deleteProduct = () => {
        const productUnit = new ProductUnit();

        setLoading(true);
        setAskDeleteMessageOpen(false);

        const cbSuccess = (res) => {
            if (res[0] === 200) {
                window.location.replace(`/products`);
            }
        }

        const cbError = (err) => {
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        productUnit.deleteProduct(productId, cbSuccess, cbError);
    }

    /**
     * @param int weekday of delivery
     */
    const handleDeliveryDaysChange = (weekday) => {

        let weekdays = [...getValues('deliveryDays')];

        if (weekdays.includes(weekday)) {
            const position = weekdays.indexOf(weekday);
            weekdays.splice(position, 1);
        } else {
            weekdays.push(weekday);
        }

        wrlog(weekdays);
        setValue('deliveryDays', weekdays);

    }

    if (is404 === true) {
        return <NotFound />;
    }

    let cutOffDaysArray = [];
    for (let i = 1; i <= 10; i++) {
        cutOffDaysArray.push(i);
    }
    let maxPauseDaysArray = [];
    for (let i = 1; i <= 120; i++) {
        maxPauseDaysArray.push(i);
    }

    let intervalOfDeliveryArray = [
        {
            label: "jede Woche",
            value: "1_week"
        },
        {
            label: "alle 2 Wochen",
            value: "2_weeks"
        },
        {
            label: "alle 4 Wochen",
            value: "4_weeks"
        }
    ];

    let deliveryWeekdaysArray = [
        {
            label: "Montag",
            value: 1
        },
        {
            label: "Dienstag",
            value: 2
        },
        {
            label: "Mittwoch",
            value: 3
        },
        {
            label: "Donnerstag",
            value: 4
        },
        {
            label: "Freitag",
            value: 5
        },
        {
            label: "Samstag",
            value: 6
        },
        {
            label: "Sonntag",
            value: 7
        },
    ];

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Link className="go_back_button" to="/products">zurück</Link><br /><br />
                        <h1>{params.id !== "new" ? <>{product.title} {translate('edit')}</> : "Neues Produkt hinzufügen"} </h1>
                        {params.id !== "new" && hasPermission('product', ['can_delete']) && <div
                            className='small_button'
                            style={{ color: 'red' }}
                            onClick={() => setAskDeleteMessageOpen(true)}>
                            Produkt löschen
                        </div>}
                    </Col>
                </Row>
                <form onSubmit={handleSubmit(doSubmit)}>
                    <Row style={{ marginTop: 50 }}>
                        <Col md={7}>
                            <FormController
                                name={"title"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.title}
                                        id="standard-basic"
                                        label={translate('title') + '*'}
                                    />
                                )}
                            />
                            {params.id !== "new" &&
                                <TextField
                                    value={`https://secure.afreshed.at/addtocart/${params.id}`}
                                    type="disabled"
                                    className={`filled valued`}
                                    id="standard-basic"
                                    label={translate('Add-To-Cart-Link')}
                                />
                            }
                            <FormController
                                name={"sku"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.sku}
                                        id="standard-basic"
                                        label={translate('SKU') + '*'}
                                    />
                                )}
                            />
                            <FormController
                                name={"description"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextEditor
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        onChange={onChange}
                                        error={errors.description}
                                        label={translate('description')}
                                    />
                                )}
                            />
                            <FormController
                                name={"price"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.price}
                                        id="standard-basic"
                                        label={"Preis" + '*'}
                                    />
                                )}
                            />
                            <FormController
                                name={"reducedPrice"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        id="standard-basic"
                                        error={errors.reducedPrice}
                                        label={"Angebots-Preis"}
                                    />
                                )}
                            />
                            <FormController
                                name={"taxRate"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        id="standard-basic"
                                        error={errors.taxRate}
                                        label={"Steuern (zb. 20%)" + '*'}
                                    />
                                )}
                            />
                            <hr />
                            <h4>Auslieferung</h4>
                            <br />
                            <FormController
                                name={"cutOffDate"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => {

                                    if (value === undefined || value === null || value === false) {
                                        return <></>;
                                    }

                                    return <FormControl
                                        className="selectFormControl"
                                        error={errors.cutOffDate}
                                    >
                                        <InputLabel id="type">Cut-Off-Days*</InputLabel>
                                        <Select
                                            onChange={onChange}
                                            value={parseInt(value)}
                                        >
                                            {cutOffDaysArray.map((i) => <MenuItem value={i}>{i} {i === 1 ? 'Tag' : 'Tage'}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                }}
                            />
                            <FormController
                                name={"maxPauseTime"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => {

                                    if (value === undefined || value === null || value === false) {
                                        return <></>;
                                    }

                                    return <FormControl
                                        className="selectFormControl"
                                        error={errors.maxPauseTime}
                                    >
                                        <InputLabel id="type">Maximale Pausierzeit*</InputLabel>
                                        <Select
                                            onChange={onChange}
                                            defaultValue={value}
                                        >
                                            {maxPauseDaysArray.map((i) => <MenuItem value={i}>{i} {i === 1 ? 'Tag' : 'Tage'}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                }}
                            />
                            <FormController
                                name={"intervalOfDelivery"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => {

                                    if (value === undefined || value === null || value === false) {
                                        return <></>;
                                    }

                                    return <FormControl
                                        className="selectFormControl"
                                        error={errors.intervalOfDelivery}
                                    >
                                        <InputLabel id="type">Interval der Lieferung*</InputLabel>
                                        <Select
                                            onChange={onChange}
                                            defaultValue={value}
                                        >
                                            {intervalOfDeliveryArray.map((i) => <MenuItem value={i.value}>{i.label} </MenuItem>)}
                                        </Select>
                                    </FormControl>
                                }}
                            />

                            <FormController
                                name={"deliveryDays"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => {

                                    return <><FormControl
                                        error={errors.intervalOfDelivery}
                                    >
                                        <br />
                                        <p>Versandtage*</p>
                                        {
                                            deliveryWeekdaysArray.map(day => (
                                                <FormControlLabel
                                                    label={day.label}
                                                    style={{ marginBottom: 10 }}
                                                    control={
                                                        <Checkbox
                                                            onChange={() => handleDeliveryDaysChange(day.value)}
                                                            checked={value !== undefined && value.includes(day.value)}
                                                        />
                                                    }
                                                />
                                            ))
                                        }

                                    </FormControl>
                                    </>
                                }}
                            />

                        </Col>
                        <Col md={5}>
                            <ImageInput
                                value={imageData?.base64}
                                imageUrl={product.imageUrl}
                                onChange={setImageData}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 100 }}>
                        <Col md={5}>
                            {
                                ((hasPermission('product', ['can_update']) && params.id !== "new") || (hasPermission('product', ['can_create']) && params.id === "new")) &&
                                <input type="submit" style={{ width: '200px', position: 'fixed', bottom: 20, right: 20 }} className="button" value={params.id !== "new" ? translate('save') : translate('add')} />
                            }
                        </Col>
                        <Col md={7}>
                        </Col>
                    </Row>
                </form>
            </Container>
            <Message
                open={errorMessageOpen}
                type={errorMessageType}
                small={errorMessageSmall}
                title={errorMessageTitle}
                body={errorMessageBody}
                buttonText={errorMessageButtonText}
                buttonAction={() => setErrorMessageOpen(false)}
            />

            <Message
                open={askDeleteMessageOpen}
                type={'success'}
                small={false}
                title={translate('sure_delete_title')}
                body={translate('sure_delete_body')}
                buttonText={translate('yes_delete')}
                buttonAction={() => deleteProduct()}
                buttonTwoText={translate('cancel')}
                buttonTwoAction={() => setAskDeleteMessageOpen(false)}
                buttonTwoStyle={{ color: 'red' }}
            />

            <Message
                open={deliveryDatesUpdateMessageOpen}
                type={'info'}
                small={false}
                title={"Wichtiger Hinweis"}
                body={"Es wird einige Minuten dauern, bis alle Versanddaten umgestellt sind."}
                buttonText={"OK"}
                buttonAction={() => setDeliveryDatesUpdateMessageOpen(false)}
            />

            <Loading visible={loading} />
        </>

    )

}

export default ProductSingle;