import Unit from './index';

export default class CustomerUnit extends Unit {

    listCustomers = (body, cbSuccess, cbError) => {
        this.post(`/customers/list`, body, cbSuccess, cbError);
    }

    getCustomer = (customerId, cbSuccess, cbError) => {
        this.get(`/customers/${customerId}`, cbSuccess, cbError);
    }

    getSubscriptions = (customerId, cbSuccess, cbError) => {
        this.get(`/customers/${customerId}/subscriptions`, cbSuccess, cbError);
    }

    getLog = (customerId, cbSuccess, cbError) => {
        this.get(`/customers/${customerId}/log`, cbSuccess, cbError);
    }

    addLogEntry = (customerId, body, cbSuccess, cbError) => {
        this.post(`/customers/${customerId}/log`, body, cbSuccess, cbError);
    }

    createCustomer = (body, cbSuccess, cbError) => {
        this.post('/customers', body, cbSuccess, cbError);
    }

    updateCustomer = (body, cbSuccess, cbError) => {
        this.put('/customers', body, cbSuccess, cbError);
    }

    deleteCustomer = (customerId, cbSuccess, cbError) => {
        this.delete(`/customers`, { id: customerId }, cbSuccess, cbError);
    }
}
