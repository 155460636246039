export const BASENAME = '';

const SSL = true;
export const SERVER_HOST = 'api.spectory.at/afreshed';
export const SERVER_PORT = '';
export const API_VERSION = '/v1';

export const API_ENDPOINT = (SSL ? 'https://' : 'http://') + SERVER_HOST + API_VERSION + SERVER_PORT;


export const FTP = {
    HOST: 'access869728043.webspace-data.io',
    PORT: '22',
    USER: 'u104597794-',
    PASS: '3%9{7)/CB%{6#3eLD116Feb#3c',
    REMOTE_URL: '/clickandbuilds/testing-admin'
}