import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';

const Message = ({ title, open, body, type, buttonText, buttonAction, small, buttonTwoText, buttonTwoAction, buttonTwoStyle }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (open) {
            setIsOpen(true);
            setShow(true);
        } else {
            setShow(false);
            setTimeout(() => {
                setIsOpen(false);
            }, 200);
        }
    }, [open]);

    if (!isOpen) {
        return null;
    }

    const container = () => (
        <div className={`${styles.container} ${small === true ? styles.small : undefined}`}>
            <h3 className={`${type === 'error' ? styles.error : undefined}`}>{title}</h3>
            {body !== undefined && body !== '' && <div className={styles.text}>{body}</div>}
            <div
                className={`button ${styles.button} ${type === 'error' ? styles.error : undefined}`}
                onClick={buttonAction}
            >
                {buttonText}
            </div>

            {buttonTwoText !== undefined && buttonTwoAction !== undefined &&
                <div
                    className={`small_button ${styles.button} ${type === 'error' ? styles.error : styles.success}`}
                    style={{marginTop: 20, ...buttonTwoStyle}}
                    onClick={buttonTwoAction}
                >
                    {buttonTwoText}
                </div>}
        </div>
    );

    if (small === true) {
        return container();
    }

    return <><div className={`${styles.overlay} ${show ? styles.show : undefined}`}>
        {container()}
    </div>
    </>

}

export default Message;