import Unit from './index';

export default class InvoiceUnit extends Unit {

    listInvoices = (body, cbSuccess, cbError) => {
        this.post(`/invoices/list`, body, cbSuccess, cbError);
    }

    getInvoice = (invoiceId, cbSuccess, cbError) => {
        this.get(`/invoices/${invoiceId}`, cbSuccess, cbError);
    }

    getPdf = (invoiceId, cbSuccess, cbError) => {
        this.get(`/invoices/${invoiceId}/pdf`, cbSuccess, cbError);
    }

    createInvoice = (body, cbSuccess, cbError) => {
        this.post('/invoices', body, cbSuccess, cbError);
    }

    updateInvoice = (body, cbSuccess, cbError) => {
        this.put('/invoices', body, cbSuccess, cbError);
    }

    getPdfs = (body, cbSuccess, cbError) => {
        this.post('/invoices/pdf', body, cbSuccess, cbError);
    }

    issueInvoice = (invoiceId, cbSuccess, cbError) => {
        this.post('/invoices/issue', { id: invoiceId }, cbSuccess, cbError);
    }

    pauseInvoice = (body, cbSuccess, cbError) => {
        this.post('/invoices/paid', body, cbSuccess, cbError);
    }

    cancelInvoice = (invoiceId, cbSuccess, cbError) => {
        this.post('/invoices/cancel', { id: invoiceId }, cbSuccess, cbError);
    }

    deleteInvoice = (invoiceId, cbSuccess, cbError) => {
        this.delete(`/invoices`, { id: invoiceId }, cbSuccess, cbError);
    }
}
