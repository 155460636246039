import Unit from './index';

export default class StatisticsUnit extends Unit {

    products = (cbSuccess, cbError) => {
        this.get('/statistics/products', cbSuccess, cbError);
    }

    customers = (cbSuccess, cbError) => {
        this.get('/statistics/customers', cbSuccess, cbError);
    }

    subscriptions = (cbSuccess, cbError) => {
        this.get('/statistics/subscriptions', cbSuccess, cbError);
    }

}



