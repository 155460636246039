import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import StatisticsUnit from '../../communicator/http/Statistics';
import ToolTip from '../../elements/tooltip';
import Loading from '../../helper/screens/Loading';
import { translate } from '../../helper/multilingual';
import TopBar from '../../elements/topbar';
import wrlog from '../../helper/functions/wrlog';
import Card, { CardContainer } from '../../elements/card';
import Cache from '../../communicator/local';


const Dashboard = () => {

    const [loading, setLoading] = useState(false);
    const [searchNameTerm, setSearchNameTerm] = useState('');
    const [name, setName] = useState('');

    const [productsCount, setProductsCount] = useState(0);
    const [customersCount, setCustomersCount] = useState(0);
    const [subscriptionsCount, setSubscriptionsCount] = useState({
        total: 0,
        active: 0,
        paused: 0,
        canceled: 0,
    });

    useEffect(() => {
        const statisticsUnit = new StatisticsUnit();

        statisticsUnit.products((res) => setProductsCount(res.products), () => { })
        statisticsUnit.customers((res) => setCustomersCount(res.customers), () => { })
        statisticsUnit.subscriptions((res) => setSubscriptionsCount(res), () => { })

    }, [])

    const queryArgsCache = new Cache('subscription.queryArgs', true);

    const setAllSubscriptionCache = () => {
        queryArgsCache.set({
            skip: 0, take: 25, search: "", type: "", postcodes: [], product_statuses: [], products: [], delivery_date: "", page: 1
        });
    }

    const setActiveSubscriptionCache = () => {
        queryArgsCache.set({
            skip: 0, take: 25, search: "", type: "", postcodes: [], product_statuses: ["active"], products: [], delivery_date: "", page: 1
        });
    }

    const setPausedSubscriptionCache = () => {
        queryArgsCache.set({
            skip: 0, take: 25, search: "", type: "", postcodes: [], product_statuses: ["paused"], products: [], delivery_date: "", page: 1
        });
    }

    const setCanceledSubscriptionCache = () => {
        queryArgsCache.set({
            skip: 0, take: 25, search: "", type: "", postcodes: [], product_statuses: ["canceled"], products: [], delivery_date: "", page: 1
        });
    }

    return <>
        <Container >
            <TopBar name={name} logout={() => {
                localStorage.removeItem('token');
                localStorage.removeItem('isAdmin');
                window.location.reload();
            }} />
            <Row>
                <Col md={6}>
                    <CardContainer style={{ gridTemplateColumns: "1fr 1fr" }}>
                        <Card counter to="/products">
                            <div className={"content"}>
                                <h2>{productsCount}</h2>
                                <span>Produkte</span>
                            </div>
                        </Card>
                        <Card counter to="/customers">
                            <div className={"content"}>
                                <h2>{customersCount}</h2>
                                <span>Kunden</span>
                            </div>
                        </Card>
                    </CardContainer>
                </Col>
                <Col md={6}>
                    <CardContainer style={{ gridTemplateColumns: "1fr 1fr" }}>
                        <Card counter to="/subscriptions" onClick={setAllSubscriptionCache}>
                            <div className={"content"}>
                                <h2>{subscriptionsCount.total}</h2>
                                <span>Alle Abonnements</span>
                            </div>
                        </Card>
                        <Card counter to="/subscriptions" onClick={setActiveSubscriptionCache}>
                            <div className={"content"}>
                                <h2>{subscriptionsCount.active}</h2>
                                <span>Aktive Abonnements</span>
                            </div>
                        </Card>
                        <Card counter to="/subscriptions" onClick={setPausedSubscriptionCache}>
                            <div className={"content"}>
                                <h2>{subscriptionsCount.paused}</h2>
                                <span>Pausierte Abonnements</span>
                            </div>
                        </Card>
                        <Card counter to="/subscriptions" onClick={setCanceledSubscriptionCache}>
                            <div className={"content"}>
                                <h2>{subscriptionsCount.canceled}</h2>
                                <span>Stornierte Abonnements</span>
                            </div>
                        </Card>
                    </CardContainer>
                </Col>
            </Row>
        </Container>
        <Loading visible={loading} />
    </>

}

export default Dashboard;