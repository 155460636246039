import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { translate } from '../../helper/multilingual';

import styles from './style.module.scss';

const StatusLabel = ({children, status}) => {
    return <div className={`${styles.container} ${styles[status]}`}>{children}</div>
}

export default StatusLabel;