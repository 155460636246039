import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { hasPermission } from '../../helper/functions/auth';
import Cache from '../../communicator/local';

import styles from './style.module.scss';
import Logo from '../../assets/logo.png';
import wrlog from '../../helper/functions/wrlog';

const Navigation = () => {

    const location = useLocation();

    const deleteSubscriptionCache = () => {
        const queryArgsCache = new Cache('subscription.queryArgs', true);
        queryArgsCache.delete();
    }

    const deleteCustomersCache = () => {
        const queryArgsCache = new Cache('customers.queryArgs', true);
        queryArgsCache.delete();
    }

    const deleteProductsCache = () => {
        const queryArgsCache = new Cache('products.queryArgs', true);
        queryArgsCache.delete();
    }

    const deleteInvoiceCache = () => {
        const queryArgsCache = new Cache('invoices.queryArgs', true);
        queryArgsCache.delete();
    }

    return <div className={styles.container}>
        <img src={Logo} />
        <Link className={`${styles.button} ${location.pathname === '/' ? styles.current : undefined}`} to="/">Dashboard</Link>
        {hasPermission('admin', ['can_read', 'can_create', 'can_update', 'can_delete']) && <Link className={`${styles.button} ${location.pathname.includes('/admins') ? styles.current : undefined}`} to="/admins">Admins</Link>}
        {hasPermission('product', ['can_read', 'can_create', 'can_update', 'can_delete']) && <Link onClick={deleteProductsCache} className={`${styles.button} ${location.pathname.includes('/products') ? styles.current : undefined}`} to="/products">Produkte</Link>}
        {hasPermission('customer', ['can_read', 'can_create', 'can_update', 'can_delete']) && <Link onClick={deleteCustomersCache} className={`${styles.button} ${location.pathname.includes('/customers') ? styles.current : undefined}`} to="/customers">Kunden</Link>}
        {hasPermission('subscription', ['can_read', 'can_create', 'can_update', 'can_delete']) && <Link onClick={deleteSubscriptionCache} className={`${styles.button} ${location.pathname.includes('/subscriptions') ? styles.current : undefined}`} to="/subscriptions">Abonnements</Link>}
        {hasPermission('invoice', ['can_read', 'can_create', 'can_update', 'can_delete']) && <Link onClick={deleteInvoiceCache} className={`${styles.button} ${location.pathname.includes('/invoices') ? styles.current : undefined}`} to="/invoices">Rechnungen</Link>}
        {/* <Link className={`${styles.button} ${location.pathname == '/check' ? styles.current : undefined}`} to="/check">Manuelle Freigabe</Link> */}
        {/* <Link className={`${styles.button} ${location.pathname == '/validities' ? styles.current : undefined}`} to="/validities">Gültigkeiten</Link> */}

        <div className={`${styles.logoutButton} ${styles.button}`} onClick={() => {
            localStorage.removeItem('token');
            localStorage.removeItem('isAdmin');
            window.location.reload();
        }}>Logout</div>
    </div>

}

export default Navigation;