import { Checkbox, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, FormControl, FormControlLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import Loading from '../../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";

import CustomerUnit from '../../../communicator/http/Customers';
import { translate } from '../../../helper/multilingual';
import Message from '../../../elements/message';
import NotFound from '../../NotFound';
import { hasPermission } from '../../../helper/functions/auth';
import Card from '../../../elements/card';
import { formatDbDate } from '../../../helper/functions/formatDate';
import ContactDataForm from './contactDataForm';
import SubscriptionUnit from '../../../communicator/http/Subscriptions';
import Popup from '../../../elements/popup';
import { DateRangePicker } from 'react-date-range';
import { de } from 'date-fns/locale'

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import LogEntry from '../../../elements/logEntry';
import LogNoteForm from './logNoteForm';
import { Autocomplete } from '@material-ui/lab';
import ProductUnit from '../../../communicator/http/Products';
import StatusLabel from '../../../elements/statusLabel';

/**
 * 
 * @returns Customer Detail Page
 */
const CustomerSingle = (props) => {

    const location = useLocation();

    // wrlog("PROPS", location.state.returnUrl);

    const params = useParams();

    const customerId = params.id;

    const [loading, setLoading] = useState(customerId !== 'new');
    const [customer, setCustomer] = useState({});
    const [subscriptions, setSubscriptions] = useState([]);
    const [customerEdit, setCustomerEdit] = useState(false);
    const [addingLogEntry, setAddingLogEntry] = useState(false);
    const [pausePopupOpen, setPausePopupOpen] = useState(false);
    const [addSubscriptionPopupOpen, setAddSubscriptionPopupOpen] = useState(false);
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);

    const [logEntries, setLogEntries] = useState([]);
    const [products, setProducts] = useState([]);
    const [newProduct, setNewProduct] = useState(null);


    const [pauseRange, setPauseRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [askDeleteMessageOpen, setAskDeleteMessageOpen] = useState(false);

    const [is404, setIs404] = useState(false);

    const { handleSubmit, setValue, control, formState: { errors }, getValues, register } = useForm();

    /**
     * Inititally check if customerId is "new" to check if customer is updated or new customer is created
     */
    useEffect(() => {

        const customerUnit = new CustomerUnit();

        /**
         * if customerId !== set set 404 = true
         */
        if (customerId === undefined || customerId === null || customerId === "") {
            setIs404(true);
            return;
        }

        /**
         * if customerId !== new --> update customer & load customerData
         */
        if (customerId !== 'new') {

            // Check if customer can update || delete

            if (!hasPermission('customer', ['can_update', 'can_delete', 'can_read'])) {
                setIs404(true);
                return;
            }

            customerUnit.getCustomer(customerId, (customer) => {

                setLoading(false);

                setCustomer(customer);

            }, () => {
                setIs404(true);
            })

            loadSubscriptions();

        } else {
            if (!hasPermission('customer', ['can_create'])) {
                setIs404(true);
                return;
            }

            setValue('intervalOfDelivery', "");
            setValue('maxPauseTime', "");
            setValue('cutOffDate', "");
            setValue('deliveryDays', []);

        }


    }, [customerId]);

    /**
     * initially loads products
     */
    useEffect(() => {

        const productUnit = new ProductUnit();

        productUnit.listProducts({
            skip: 0,
            take: 10000,
            search: ""
        }, (res) => {

            let _products = [];
            res.results.forEach(_product => {
                _products.push({
                    title: _product.title,
                    id: _product.id,
                });
            });

            setProducts(_products);
        }, (err) => {
            setLoading(false);
        })
    }, []);

    /**
     * Loads the subscriptions of the user
     * @param {Function} cb 
     */
    const loadSubscriptions = (cb = () => { }) => {
        const customerUnit = new CustomerUnit();
        customerUnit.getSubscriptions(customerId, (_subscriptions) => {
            setLoading(false);
            setSubscriptions(_subscriptions);
            loadLog();
            cb();
        }, () => {
            setLoading(false);

            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle("Unbekannter Fehler");
            setErrorMessageBody("Ein unbekannter Fehler ist aufgetreten bitte laden Sie die Seite neu. Sollte das Problem weiterhin bestehen wenden Sie sich an den Systemadministrator.");
        })
    }

    /**
 * Loads the log of the user
 * @param {Function} cb 
 */
    const loadLog = (cb = () => { }) => {
        const customerUnit = new CustomerUnit();
        customerUnit.getLog(customerId, (log) => {
            setLoading(false);
            setLogEntries(log);
            cb();
        }, () => {
            setLoading(false);

            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle("Unbekannter Fehler");
            setErrorMessageBody("Ein unbekannter Fehler ist aufgetreten bitte laden Sie die Seite neu. Sollte das Problem weiterhin bestehen wenden Sie sich an den Systemadministrator.");
        })
    }



    /**
     * Delete current customer
     */
    const deleteCustomer = () => {
        const customerUnit = new CustomerUnit();

        setLoading(true);
        setAskDeleteMessageOpen(false);

        const cbSuccess = (res) => {
            window.location.replace(`/customers`);
        }

        const cbError = (err) => {
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        customerUnit.deleteCustomer(customerId, cbSuccess, cbError);
    }

    /**
     * Change the status of the subscription
     * 
     * @param string status
     * 
     * @return void
     */
    const changeStatus = (status, subscriptionId) => {
        const subscriptionUnit = new SubscriptionUnit();

        let body = {
            status: status,
            id: subscriptionId
        }

        setSelectedSubscriptionId(subscriptionId)

        if (status === 'paused') {
            setPausePopupOpen(true);
        } else if (status === 'canceled') {
            subscriptionUnit.cancelSubscription(subscriptionId, (res) => {
                loadSubscriptions(() => {
                    setLoading(false);
                });
            }, (err) => {
                setErrorMessageButtonText("OK");
                setErrorMessageType("error");
                setErrorMessageOpen(true);
                setErrorMessageSmall(false);
                setErrorMessageTitle(err.response.data.exception.title);
                setErrorMessageBody(err.response.data.exception.message);
            })
        } else {
            subscriptionUnit.activateSubscription(subscriptionId, (res) => {
                loadSubscriptions(() => {
                    setLoading(false);
                });
            }, (err) => {
                setErrorMessageButtonText("OK");
                setErrorMessageType("error");
                setErrorMessageOpen(true);
                setErrorMessageSmall(false);
                setErrorMessageTitle(err.response.data.exception.title);
                setErrorMessageBody(err.response.data.exception.message);
            })
        }

    }

    const setPause = () => {

        const subscriptionUnit = new SubscriptionUnit();
        const customerUnit = new CustomerUnit();

        setLoading(true);

        let body = {
            id: selectedSubscriptionId,
            start: pauseRange.startDate.toDateString(),
            end: pauseRange.endDate.toDateString()
        }

        const cbSuccess = (res) => {
            loadSubscriptions(() => {
                setPausePopupOpen(false)
            });
        }

        const cbError = (err) => {
            setLoading(false);
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        subscriptionUnit.pauseSubscription(body, cbSuccess, cbError);
    }

    const onChangeDate = (dates) => {
        setPauseRange(dates.selection)
    }

    const createSubscription = () => {
        setLoading(true);

        const cbSuccess = (res) => {
            loadSubscriptions(() => {
                setLoading(false);
                setAddSubscriptionPopupOpen(false);
            });
        }
        const cbError = (err) => {
            setAddSubscriptionPopupOpen(false);
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }
        const body = {
            customer_id: customerId,
            product_id: newProduct,
        }

        const subscriptionUnit = new SubscriptionUnit();
        subscriptionUnit.createSubscription(body, cbSuccess, cbError);
    }

    const renderPausedDetails = (subscription) => {
        return <>
            Pausiert<br />
            Von: {formatDbDate(subscription.pauseStart)}<br />
            Bis: {formatDbDate(subscription.pauseEnd)}
        </>
    }

    const renderCanceledDetails = (subscription) => {
        return <>
            Storniert am {formatDbDate(subscription.canceledAt)}
            {
                subscription.lastDeliveryDate !== undefined && <><br />Letzte Lieferung: {formatDbDate(subscription.lastDeliveryDate)}</>
            }
        </>
    }


    if (is404 === true) {
        return <NotFound />;
    }


    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Link className="go_back_button" to={location.state?.returnUrl || "/customers"}>zurück</Link><br /><br />
                        <h1>{`${customer.firstName} ${customer.lastName} ${translate('edit')}`}</h1>
                        {hasPermission('customer', ['can_delete']) && <div
                            className='small_button'
                            style={{ color: 'red' }}
                            onClick={() => setAskDeleteMessageOpen(true)}>
                            Kunde löschen
                        </div>}
                    </Col>
                </Row>
                <Row style={{ marginTop: 50 }}>
                    <Col md={8}>
                        <Card style={{ marginBottom: 40 }}>
                            <h4>Kontaktdaten</h4>
                            {customerEdit ?
                                <ContactDataForm
                                    {...customer}
                                    customerId={customerId}
                                    setShow={setCustomerEdit}
                                    setCustomer={setCustomer}
                                    isCreate={false}
                                />
                                :
                                <Container fluid style={{ padding: 0, marginTop: 10 }}>
                                    <Row>
                                        <Col md={6}>
                                            {customer.type === 'b2b' ? "Businesskunde" : "Privatkunde"}<br /><br />
                                            {customer.firstName} {customer.lastName}<br />
                                            {customer.company !== '' ? <>{customer.company}<br /></> : ''}
                                            {customer?.vatNumber !== '' ? <>{customer?.vatNumber} (VAT)<br /></> : ''}

                                            <br />
                                            <strong>Lieferadresse</strong><br />
                                            {customer?.address?.address1} {customer?.address?.streetNumber}<br />
                                            {customer.address?.address2 !== '' ? <>{customer.address?.address2}<br /></> : ''}
                                            {customer?.address?.postcode} {customer?.address?.city}<br />
                                            {customer?.address?.country}

                                            {
                                                customer.type === "b2b" && <>
                                                    <br />
                                                    <br />
                                                    <strong>Rechnungsadresse</strong>
                                                    <br />
                                                    {customer?.billing?.address1}
                                                    <br />
                                                    {customer.billing?.address2 !== '' ? <>{customer.billing?.address2}<br /></> : ''}
                                                    {customer?.billing?.postcode} {customer?.billing?.city}<br />
                                                    {customer.billing?.country !== '' ? <>{customer.billing?.country}</> : ''}

                                                </>
                                            }
                                        </Col>
                                        <Col md={6}>
                                            <a href={`mailto:${customer.email}`}>{customer.email}</a><br />
                                            <a href={`tel:${customer.phone}`}>{customer.phone}</a><br /><br />
                                            {customer.note}
                                        </Col>
                                    </Row>
                                    {hasPermission('customer', ['can_update']) &&
                                        <div
                                            onClick={() => setCustomerEdit(true)}
                                            style={{ color: 'var(--green-color)', position: 'absolute', top: 20, right: 30, cursor: 'pointer' }}>
                                            bearbeiten
                                        </div>
                                    }
                                </Container>
                            }


                        </Card>
                    </Col>
                    <Col md={4}>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card style={{ marginBottom: 40 }}>
                            <h4>Abonnements</h4>
                            <table>
                                <tr>
                                    <td><strong>Produkt</strong></td>
                                    <td><strong>nächste Lieferung</strong></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                {
                                    subscriptions.map(subscription => {
                                        return <tr>
                                            <td>{subscription.productTitle}</td>
                                            <td>{formatDbDate(subscription.nextDeliveryDate)}</td>
                                            <td>
                                                {
                                                    subscription.status === 'active' && <></>
                                                }
                                                {
                                                    subscription.status === 'paused' && renderPausedDetails(subscription)
                                                }
                                                {
                                                    subscription.status === 'canceled' && renderCanceledDetails(subscription)
                                                }
                                            </td>
                                            <td style={{ width: 130 }}>
                                                {(hasPermission('customer', ['can_update']) || hasPermission('subscription', ['can_update'])) ?

                                                    <Select
                                                        className={`statusSelect ${subscription.status}`}
                                                        value={subscription.status}
                                                        onChange={e => changeStatus(e.target.value, subscription.id)}
                                                    >
                                                        <MenuItem value="active">Aktiv</MenuItem>
                                                        <MenuItem value="paused">Pausiert</MenuItem>
                                                        <MenuItem value="canceled">Storniert</MenuItem>
                                                    </Select>
                                                    : <StatusLabel status={subscription.status}>{subscription.status}</StatusLabel>
                                                }
                                            </td>
                                        </tr>
                                    })
                                }
                            </table>
                            {(hasPermission('customer', ['can_update']) || hasPermission('subscription', ['can_update', 'can_create'])) &&
                                <div
                                    onClick={() => setAddSubscriptionPopupOpen(true)}
                                    style={{ color: 'var(--green-color)', position: 'absolute', top: 20, right: 30, cursor: 'pointer' }}
                                >
                                    Abonnement hinzufügen
                                </div>
                            }
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                    </Col>
                    <Col md={4}>
                        <Card style={{ marginBottom: 40 }}>
                            <h4>Logs</h4>
                            <div style={{ maxHeight: 400, overflow: 'scroll' }}>
                                {addingLogEntry ?
                                    <>
                                        <LogNoteForm
                                            setShow={setAddingLogEntry}
                                            setLogEntries={setLogEntries}
                                            customerId={customerId}
                                        />
                                    </>
                                    :
                                    <>
                                        {hasPermission('customer', ['can_update']) &&
                                            <div
                                                onClick={() => setAddingLogEntry(true)}
                                                style={{ color: 'var(--green-color)', position: 'absolute', top: 20, right: 30, cursor: 'pointer' }}>Notiz hinzufügen</div>
                                        }
                                        {
                                            logEntries.map(logEntry => {
                                                return <LogEntry {...logEntry} />;
                                            })
                                        }
                                    </>
                                }
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 100 }}>
                    <Col md={5}>
                    </Col>
                    <Col md={7}>
                    </Col>
                </Row>
            </Container>
            <Popup
                close={() => setPausePopupOpen(false)}
                open={pausePopupOpen}
                style={{ width: 372, paddingTop: 50 }}
            >
                <DateRangePicker
                    ranges={[pauseRange]}
                    onChange={onChangeDate}
                    minDate={new Date()}
                    locale={de}
                    staticRanges={[]}
                    inputRanges={[]}
                    renderStaticRangeLabel={{ hasCustomRendering: false }}
                />
                <div
                    className="button"
                    onClick={setPause}
                >Pausieren</div>
            </Popup>
            <Popup
                close={() => setAddSubscriptionPopupOpen(false)}
                open={addSubscriptionPopupOpen}
                style={{ paddingTop: 50 }}
            >
                <Autocomplete
                    id="tags-outlined"
                    options={products}
                    getOptionLabel={(option) => option.title}
                    onChange={(event, newValue) => {
                        setNewProduct(newValue.id);
                    }}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Produkte"
                            className="filled"
                        />
                    )}
                />
                <div
                    className="button"
                    style={{ marginTop: 20 }}
                    onClick={createSubscription}
                >Abonnement hinzufügen</div>
            </Popup>
            <Message
                open={errorMessageOpen}
                type={errorMessageType}
                small={errorMessageSmall}
                title={errorMessageTitle}
                body={errorMessageBody}
                buttonText={errorMessageButtonText}
                buttonAction={() => setErrorMessageOpen(false)}
            />

            <Message
                open={askDeleteMessageOpen}
                type={'success'}
                small={false}
                title={translate('sure_delete_title')}
                body={translate('sure_delete_body')}
                buttonText={translate('yes_delete')}
                buttonAction={() => deleteCustomer()}
                buttonTwoText={translate('cancel')}
                buttonTwoAction={() => setAskDeleteMessageOpen(false)}
                buttonTwoStyle={{ color: 'red' }}
            />

            <Loading visible={loading} />
        </>

    )

}

export default CustomerSingle;