import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import styles from './style.module.scss';

const TextEditor = (props) => {

    const [content, setContent] = useState('');

    useEffect(() => {

        if (props.value === false || props.value === undefined || props.value === null || content !== '') {
            return;
        }

        const contentBlock = htmlToDraft(props.value);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);

            setContent(editorState);
        }

    }, [props.value])


    const onEditorStateChange = (editorState) => {
        setContent(editorState);

        props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };



    return <>
        <p class={styles.placeholder}>{props.label}</p>
        <Editor
            wrapperClassName={styles.wrapper}
            editorClassName={styles.editor}
            toolbarClassName={styles.toolbar}
            editorState={content}
            onEditorStateChange={onEditorStateChange}
        />
    </>

}

export default TextEditor;