import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.scss';

import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import Login from './screens/Login';
import { hasPermission, isLoggedIn } from './helper/functions/auth';
import Loading from './helper/screens/Loading';
import UserUnit from './communicator/http/User';
import AdminUsersEdit from './screens/AdminUsers/Add_Edit';
import Cache from './communicator/local';

import { BASENAME } from './spectory-config';
import Dashboard from './screens/Dashboard';
import { changeLanguageHandler } from './helper/multilingual';
import PasswordForgot from './screens/PasswordForgot';
import PasswordReset from './screens/PasswordReset';
import Navigation from './elements/navigation';
import Admins from './screens/AdminUsers';
import NotFound from './screens/NotFound';
import wrlog from './helper/functions/wrlog';
import Products from './screens/Products';
import ProductSingle from './screens/Products/Single';
import Customers from './screens/Customers';
import CustomerSingle from './screens/Customers/Single';
import Subscriptions from './screens/Subscriptions';
import Invoices from './screens/Invoices';
import ServerLog from './screens/ServerLog';

const App = () => {

    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);


    useEffect(() => {
        changeLanguageHandler();
        const userUnit = new UserUnit();

        isLoggedIn().then((res) => {

            localStorage.setItem('token', res.token);

            userUnit.getPermissions((permissions) => {

                sessionStorage.setItem('permissions', JSON.stringify(permissions));

                setLoggedIn(true);
                setLoading(false);

            }, (err) => wrlog("ERR", err));

        }).catch(() => {
            setLoading(false);
            setLoggedIn(false);
            localStorage.removeItem('token')
        })

        // DELETE ALL CACHES NECCESSARY
        let queryArgsCache = new Cache('subscription.queryArgs', true);
        queryArgsCache.delete();
        queryArgsCache = new Cache('customers.queryArgs', true);
        queryArgsCache.delete();
        queryArgsCache = new Cache('products.queryArgs', true);
        queryArgsCache.delete();

    }, []);

    if (loading) {
        return <Loading visible={loading} />;
    }

    if (loggedIn) {

        return (
            <Router basename={BASENAME}>
                <div className="wrapper">
                    <Navigation />
                    <div className="content_wrapper">
                        <Routes>
                            <Route path='/' element={<Dashboard />} />
                            {hasPermission('admin', 'can_read') && <Route path='/admins' element={<Admins />} />}
                            {hasPermission('admin', 'can_read') && <Route path='/admins/:id' element={<AdminUsersEdit />} />}

                            {hasPermission('product', 'can_read') && <Route path='/products' element={<Products />} />}
                            {hasPermission('product', 'can_read') && <Route path='/products/:id' element={<ProductSingle />} />}

                            {hasPermission('customer', 'can_read') && <Route path='/customers' element={<Customers />} />}
                            {hasPermission('customer', 'can_read') && <Route path='/customers/:id' element={<CustomerSingle />} />}

                            {hasPermission('subscription', 'can_read') && <Route path='/subscriptions' element={<Subscriptions />} />}

                            {hasPermission('invoice', 'can_read') && <Route path='/invoices' element={<Invoices />} />}
                            {hasPermission('admin', 'can_delete') && <Route path='/logs' element={<ServerLog />} />}
                            <Route path='*' element={<NotFound />} />
                        </Routes>
                    </div>
                </div>
            </Router>
        );

    } else {
        return (
            <Router basename={BASENAME}>
                <Routes>

                    <Route path='/forgot-password' element={<PasswordForgot />} />
                    <Route path='/reset-password/:key/:secret' element={<PasswordReset />} />
                    <Route path='*' element={<Login />} />
                </Routes>
            </Router>
        );
    }
}

export default App;