import Unit from './index';

export default class SubscriptionUnit extends Unit {

    listSubscriptions = (body, cbSuccess, cbError) => {
        this.post(`/subscriptions/list`, body, cbSuccess, cbError);
    }

    getSubscription = (subscriptionId, cbSuccess, cbError) => {
        this.get(`/subscriptions/${subscriptionId}`, cbSuccess, cbError);
    }

    getSubscriptions = (subscriptionId, cbSuccess, cbError) => {
        this.get(`/subscriptions/${subscriptionId}/subscriptions`, cbSuccess, cbError);
    }

    createSubscription = (body, cbSuccess, cbError) => {
        this.post('/subscriptions', body, cbSuccess, cbError);
    }

    updateSubscription = (body, cbSuccess, cbError) => {
        this.put('/subscriptions', body, cbSuccess, cbError);
    }

    getCsv = (body, cbSuccess, cbError) => {
        this.post('/subscriptions/csv', body, cbSuccess, cbError);
    }

    pauseSubscription = (body, cbSuccess, cbError) => {
        this.post('/subscriptions/pause', body, cbSuccess, cbError);
    }

    cancelSubscription = (subscriptionId, cbSuccess, cbError) => {
        this.post('/subscriptions/cancel', { id: subscriptionId }, cbSuccess, cbError);
    }

    activateSubscription = (subscriptionId, cbSuccess, cbError) => {
        this.post('/subscriptions/activate', { id: subscriptionId }, cbSuccess, cbError);
    }

    deleteSubscription = (subscriptionId, cbSuccess, cbError) => {
        this.delete(`/subscriptions`, { id: subscriptionId }, cbSuccess, cbError);
    }
}
