import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Message from '../../elements/message';
import Loading from '../../helper/screens/Loading';
import TopBar from '../../elements/topbar';
import { translate } from '../../helper/multilingual';
import { getUserToken, hasPermission } from '../../helper/functions/auth';
import ProductUnit from '../../communicator/http/Products';
import InvoiceUnit from '../../communicator/http/Invoices';
import Pagination from '../../elements/pagination';
import wrlog from '../../helper/functions/wrlog';
import { formatDbDate } from '../../helper/functions/formatDate';
import { Autocomplete } from '@material-ui/lab';
import StatusLabel from '../../elements/statusLabel';
import ToolTip from '../../elements/tooltip';
import Cache from '../../communicator/local';
import DatePicker from '../../elements/datepicker';
import { formatMoney } from '../../helper/functions/formatPrice';

/**
 * 
 * @returns Invoices Overview Page
 */
const Invoices = () => {

    const [loading, setLoading] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);

    const [invoices, setInvoices] = useState([]);
    const [products, setProducts] = useState([]);
    const [searchNameTerm, setSearchNameTerm] = useState("");
    const [filterType, setFilterType] = useState("");
    const [filterPostcode, setFilterPostcode] = useState("");
    const [filterStatuses, setFilterStatuses] = useState("");
    const [filterInvoiceDate, setfilterInvoiceDate] = useState({
        from: new Date(),
        to: null
    });
    const [filterProducts, setFilterProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isInit, setIsInit] = useState(true);
    const [downloadUrl, setDownloadUrl] = useState('');
    const [downloadMessageOpen, setDownloadMessageOpen] = useState(false);


    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [pdfData, setPdfData] = useState("");

    const perPage = 25;

    const productUnit = new ProductUnit();
    const invoiceUnit = new InvoiceUnit();

    const queryArgsCache = new Cache('invoices.queryArgs', true);

    /**
     * initially loads products
     */
    useEffect(() => {

        let queryArgs = queryArgsCache.get();

        if (queryArgs !== false) {
            setSearchNameTerm(queryArgs.search);
            setFilterPostcode(queryArgs.postcodes.join(','));
            setFilterStatuses(queryArgs.statuses);
            setFilterProducts(queryArgs.products);

            if (queryArgs.invoice_date !== null && queryArgs.invoice_date !== "") {
                setfilterInvoiceDate({
                    from: new Date(queryArgs.invoice_date.from),
                    to: new Date(queryArgs.invoice_date.to),
                })
            }

            setPage(queryArgs.page);
        }

        productUnit.listProducts({
            skip: 0,
            take: 10000,
            search: ""
        }, (res) => {

            let _products = [];
            res.results.forEach(_product => {
                _products.push({
                    title: _product.title,
                    id: _product.id,
                });
            });

            setProducts(_products);
        }, (err) => {
        })
    }, []);


    /**
     * initially loads products
     */
    useEffect(() => {
        let queryArgs = queryArgsCache.get();
        if (page > 0 && (!isInit || queryArgs === false)) {
            setIsInit(false);
            load();
        }
    }, [page]);

    /**
     * initially loads products
     */
    useEffect(() => {
        setIsInit(false);
        setLoading(true);
        setPage(0);
        setTimeout(() => {
            setPage(1);
        }, 100)
    }, [filterType, filterProducts, filterStatuses, filterInvoiceDate]);


    useEffect(() => {
        if (pdfLoading) {
            setTimeout(() => {
                setPdfLoading(false);
            }, 1000);
        }
    }, [pdfData]);

    /**
* loads products by search result
* @param {*} e 
*/
    const doSearch = (e) => {
        e.preventDefault();
        load();
    }


    /**
     * Loads invoices from API
     */
    const load = (cb = () => { }) => {

        setLoading(true);

        const skip = (page - 1) * perPage;
        let postcodes = [];
        if (filterPostcode !== "") {
            filterPostcode.split(',').forEach((postcode, index) => {

                if (parseInt(postcode) >= 1000 && parseInt(postcode) <= 9999) {
                    postcodes.push(parseInt(postcode));
                }
            })
        }

        let productIds = [];

        filterProducts.forEach(value => {
            productIds.push(value.id);
        });

        let invoiceDate = "";
        if (filterInvoiceDate !== undefined && filterInvoiceDate !== null && filterInvoiceDate !== false && filterInvoiceDate.from !== null && filterInvoiceDate.to !== null) {
            invoiceDate = {
                from: filterInvoiceDate.from.toDateString(),
                to: filterInvoiceDate.to.toDateString()
            }
        }

        const body = {
            skip: skip,
            take: perPage,
            search: searchNameTerm,
            // type: filterType,
            postcodes: postcodes,
            statuses: filterStatuses,
            products: productIds,
            invoice_date: invoiceDate
        };

        invoiceUnit.listInvoices(body, (res) => {

            setLoading(false);
            setInvoices(res.results);
            setTotalPages(Math.ceil(res.total / perPage));
            cb();
            body.page = page;
            body.products = filterProducts;
            queryArgsCache.set(body);

        }, (err) => {
            setLoading(false);
        })
    }

    /**
     * receives the csv file
     * 
     */
    const downloadInvoice = (invoiceId = null) => {

        setLoading(true);
        invoiceUnit.getPdf(invoiceId, (res) => {

            const url = window.URL.createObjectURL(new Blob([Buffer.from(res.base64, 'base64')]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${res.title}.pdf`);
            document.body.appendChild(link);
            link.click();


            setLoading(false);
        }, (err) => {
            setLoading(false);
        })
    }

    const downloadInvoices = () => {

        setPdfLoading(true);

        let postcodes = [];
        if (filterPostcode !== "") {
            filterPostcode.split(',').forEach((postcode, index) => {
                if (parseInt(postcode) >= 1000 && parseInt(postcode) <= 9999) {
                    postcodes.push(parseInt(postcode));
                }
            })
        }

        let productIds = [];

        filterProducts.forEach(value => {
            productIds.push(value.id);
        });

        let invoiceDate = "";
        if (filterInvoiceDate !== undefined && filterInvoiceDate !== null && filterInvoiceDate !== false && filterInvoiceDate.from !== null && filterInvoiceDate.to !== null) {
            invoiceDate = {
                from: filterInvoiceDate.from.toDateString(),
                to: filterInvoiceDate.to.toDateString()
            }
        }


        const body = {
            search: searchNameTerm,
            // type: filterType,
            postcodes: postcodes,
            statuses: filterStatuses,
            products: productIds,
            invoice_date: invoiceDate
        };

        invoiceUnit.getPdfs(body, (res) => {

            let urlEncodedToken = Buffer.from(getUserToken()).toString('base64');
            setDownloadUrl(`${res.returnUrl}/${urlEncodedToken}`);
            setDownloadMessageOpen(true);

            setPdfLoading(false);
        }, (err) => {
            setPdfLoading(false);
        })
    }

    const translateInvoiceStatus = (status) => {
        const translations = {
            issued: 'offen',
            paid: 'bezahlt',
            canceled: 'storniert'
        };

        if (translations[status] === undefined) {
            return 'offen';
        }
        return translations[status];
    }

    return <>
        <TopBar title={"Rechnungen"} />
        <Container fluid>
            <Row style={{ marginTop: 44 }}>
                <Col md={12}>
                    <Row>
                        <Col md={4}>
                            <form onSubmit={doSearch}>
                                <TextField id="standard-basic" className="filled" value={searchNameTerm} label="Durchsuchen" onChange={value => setSearchNameTerm(value.target.value)} /><br />
                            </form>
                        </Col>
                        <Col md={2}>
                            <div
                                onClick={doSearch}
                                style={{ paddingTop: 17, paddingBottom: 18 }}
                                className={`button ${searchNameTerm === '' ? 'disabled' : ''}`}>
                                suchen
                            </div>
                        </Col>
                        <Col md={6} style={{ textAlign: 'right' }}>
                            <div
                                className='button'
                                onClick={downloadInvoices}>
                                {pdfLoading ? 'PDFs werden generiert...' : 'Auswahl herunterladen'}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <FormControl
                                className="selectFormControl"
                            >
                                <InputLabel id="type">Status</InputLabel>
                                <Select
                                    onChange={(e) => {
                                        setFilterStatuses(e.target.value === '' ? '' : [e.target.value])
                                    }}
                                    value={filterStatuses[0] || ""}
                                >
                                    <MenuItem value={""}>Alle</MenuItem>
                                    <MenuItem value={"issued"}>Offen</MenuItem>
                                    <MenuItem value={"paid"}>Bezahlt</MenuItem>
                                    <MenuItem value={"canceled"}>Storniert</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col md={3}>
                            <DatePicker
                                onChange={(val) => {
                                    wrlog("DATE", val);
                                    setfilterInvoiceDate({
                                        from: val[0],
                                        to: val[1]
                                    });
                                }}
                                value={filterInvoiceDate.from}
                                label={"Rechnungsdatum"}
                                startDate={filterInvoiceDate.from}
                                endDate={filterInvoiceDate.to}
                                selectsRange
                            />
                        </Col>
                        <Col md={3}>
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={products}
                                getOptionLabel={(option) => option.title}
                                value={filterProducts}
                                onChange={(event, newValue) => {
                                    setFilterProducts(newValue);
                                }}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Produkte"
                                        className="filled"
                                    />
                                )}
                            />
                        </Col>
                        <Col md={3}>
                            <form onSubmit={doSearch}>
                                <TextField id="standard-basic" className="filled" value={filterPostcode} label="Postleitzahlen" onChange={value => setFilterPostcode(value.target.value)} /><br />
                            </form>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: 44, textAlign: 'right' }}>
            </Row>
            <Row style={{ marginTop: 0 }}>
                <Col md={12}>
                    <Table className="table_container" style={{ marginTop: 20, marginBottom: 20 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Rechnungsnummer</TableCell>
                                <TableCell>Rechnungsdatum</TableCell>
                                <TableCell>Summe</TableCell>
                                <TableCell>Produkt(e)</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>E-Mail</TableCell>
                                <TableCell>PLZ</TableCell>
                                <TableCell style={{ width: 180 }}>Status</TableCell>
                                <TableCell style={{ width: 50 }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                invoices.map((invoice, key) => {
                                    return <TableRow key={key}>
                                        <TableCell>
                                            {invoice.invoiceNumber}
                                        </TableCell>
                                        <TableCell>
                                            {formatDbDate(invoice.invoiceDate)}
                                        </TableCell>
                                        <TableCell>
                                            {formatMoney(invoice.total)}
                                        </TableCell>
                                        <TableCell>
                                            {invoice.items.map(item => item.title).join(', ')}
                                        </TableCell>
                                        <TableCell>
                                            {invoice.customer.firstName} {invoice.customer.lastName}
                                        </TableCell>
                                        <TableCell>
                                            {invoice.customer.email}
                                        </TableCell>
                                        <TableCell>
                                            {invoice.customer.postcode}
                                        </TableCell>
                                        <TableCell>
                                            <StatusLabel status={invoice.status}>{translateInvoiceStatus(invoice.status)}</StatusLabel>
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip
                                                items={[{
                                                    label: "Zum Kunden",
                                                    to: `/customers/${invoice.customer.id}`,
                                                    params: {
                                                        returnUrl: `/invoices`, initQueryVars: {
                                                            skip: (page - 1) * perPage,
                                                            take: perPage,
                                                            search: searchNameTerm,
                                                            type: filterType,
                                                            invoice_date: filterInvoiceDate,
                                                            postcodes: filterPostcode,
                                                            statuses: filterStatuses,
                                                            products: filterProducts,
                                                        }
                                                    }
                                                },
                                                {
                                                    label: "herunterladen",
                                                    onClick: () => downloadInvoice(invoice.id),
                                                }]}
                                            ></ToolTip>
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                    <Pagination
                        pages={totalPages}
                        current={page}
                        pageChange={setPage}
                    />
                </Col>
            </Row>
        </Container>
        <Message
            open={errorMessageOpen}
            type={errorMessageType}
            small={errorMessageSmall}
            title={errorMessageTitle}
            body={errorMessageBody}
            buttonText={errorMessageButtonText}
            buttonAction={() => setErrorMessageOpen(false)}
        />

        <Message
            open={downloadMessageOpen}
            type={"success"}
            small={false}
            title={"PDFs werden erstellt"}
            body={"Du bekommst in den nächsten Minuten E-Mails mit Rechnungspaketen zugeschickt."}
            buttonText={"OK"}
            buttonAction={() => setDownloadMessageOpen(false)}
        />

        <Loading visible={loading} />
    </>

}

export default Invoices;